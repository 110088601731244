import React from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Pagination, Popconfirm, Row, Skeleton, Space, Table, Tooltip, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, FileSearchOutlined, PlusOutlined } from '@ant-design/icons';
import { AccountCustomersModel } from './AccountCustomersModel';
import { Customer, CustomerProps } from 'models/Customer';
import { getColumnSearchProps } from 'components/Filters/TableSearchFilter';
import { AccountCustomerModal } from './AccountCustomerModal';
import { AccountCustomerDetailsDrawer } from '../CustomerDetails/AccountCustomerDetailsDrawer';
import { AccountsStore } from 'stores/Accounts';
import { GlobalStore } from 'stores/Global';
import { formatPhoneNumber } from 'utils/Phone';

import './AccountCustomersStyle.less';
import { AccountUserRoles } from 'models/AccountUser';

interface AccountCustomersViewProps {
  model: AccountCustomersModel;
}

export const AccountCustomersView: React.FC<AccountCustomersViewProps> = observer(({ model }) => {
  const currentAccount = AccountsStore.getAccount(GlobalStore.getCurrentAccountId());
  const hasRemove = currentAccount && currentAccount.role === AccountUserRoles.OWNER;

  const columns = [{
    title: '#',
    dataIndex: '#',
    key: '#',
    width: '60px'
  }, {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ...getColumnSearchProps('name', model.handleSearch, model.handleSearchReset)
  }, {
    title: 'Company',
    dataIndex: 'company',
    key: 'company',
    render: (company: string) => company || '—'
  }, {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: (email: string) => email ? <a href={`mailto:${email}`}>{email}</a> : '—'
  }, {
    title: 'Phone Number',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    render: (phone: string) => phone ? <a href={`tel:${phone}`}>{formatPhoneNumber(phone)}</a> : '—'
  }, {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    filters: [
      { text: 'Active', value: 'Active' },
      { text: 'Archived', value: 'Archived' },
    ],
    filterMultiple: false,
    filteredValue: model.status || null,
  }, {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    ellipsis: true
  }, {
    title: 'Action',
    key: 'action',
    width: hasRemove ? '160px' : '120px',
    render: (_text: any, record: Customer) => (
      <Space size="middle">
        <AccountCustomerDetailsDrawer customerId={record.customerId} accountId={model.accountId}>
          {({ openDrawer }) => (
            <Tooltip title="View details" mouseEnterDelay={0.5}>
              <Button icon={<FileSearchOutlined/>} onClick={openDrawer}/>
            </Tooltip>
          )}
        </AccountCustomerDetailsDrawer>
        <Tooltip title="Edit" mouseEnterDelay={0.5}>
          <Button icon={<EditOutlined/>} onClick={() => model.handleEdit(record)}/>
        </Tooltip>
        {hasRemove ? (
          <Popconfirm title="Are you sure?" onConfirm={() => model.handleRemove(record)} disabled={record.status !== 'Active'}>
            <Button icon={<DeleteOutlined/>} disabled={record.status !== 'Active'} danger/>
          </Popconfirm>
        ) : null}
      </Space>
    ),
  }];

  if (model.loading) {
    return (
      <Skeleton active/>
    );
  }

  const currentPage = model.pagintaion.page;
  const currentSkip = model.pagintaion.skip;
  const currentTotal = model.pagintaion.total;
  const data: any[] = model.customers.map((customer, i) => ({
    ...customer,
    original: customer,
    '#': currentSkip + i + 1
  }));

  return (
    <div className="account-customers">
      <Typography.Title level={2} className="tc">Your Customers</Typography.Title>

      <Typography.Title level={4}>
        <Row>
          <Col span={12}>
            <Button type="primary" onClick={model.handleAdd}><PlusOutlined/> Add Customer</Button>
          </Col>
          <Col span={12}>
          </Col>
        </Row>
      </Typography.Title>

      <Table
        columns={columns as any}
        dataSource={data}
        pagination={false}
        loading={model.tableLoading}
        onChange={model.tableFiltersChange}
        rowKey={(record: CustomerProps) => record.customerId}
        footer={() => (
          <Row>
            <Col span={12}>
              <Button type="primary" onClick={model.handleAdd}><PlusOutlined/> Add Customer</Button>
            </Col>
            <Col className="tr" span={12}>
              <Pagination
                className="account-customers__pagination"
                showSizeChanger
                onShowSizeChange={model.takeChange}
                onChange={model.paginationChange}
                defaultCurrent={1}
                pageSizeOptions={['10', '20', '50', '100', '200', '500']}
                defaultPageSize={500}
                current={currentPage}
                total={currentTotal}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              />
            </Col>
          </Row>
        )}
      />

      <AccountCustomerModal model={model}/>
    </div>
  );
});